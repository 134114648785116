import React, { useState } from "react"
import { graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { Link, useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"
import useResponsive from "../hooks/useResponsive"
import useCountryCode from "../hooks/useCountryCode"

//Icons
import ArrowIcon from "../assets/icons/arrow-down.svg"
import ArrowLeftIcon from "../assets/icons/arrow.svg"

// Images
import PaymentLinkIcon from "../assets/payment-links-links.svg"
import PaymentSendIcon from "../assets/payment-links-send-payment.svg"
import PaymentClickIcon from "../assets/payment-links-click-and-pay.svg"
import PaymentAccountIcon from "../assets/payment-links-account.svg"
import Section3Item1Image from "../assets/payment-links_section3_item1.svg"
import Section3Item2Image from "../assets/payment-links_section3_item2.svg"
import Section4Item1Image from "../assets/payment-links_section4_item1.svg"
import Section4Item2Image from "../assets/payment-links_section4_item2.svg"
import Section4Item3Image from "../assets/payment-links_section4_item3.svg"
import Dot from "../assets/payment-links-dot.svg"

// Components
import SectionTitle from "../components/section-title"
import FAQ from "../components/FAQ-inpage"

// Styles
import "../styles/pages/payment-link.scss"

const HeroSection = ({ imageFluid, guides }) => {
  const { t } = useTranslation()
  const { locale } = useIntl()

  return (
    <section className="section is-hero ">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1 className="title main-title has-text-white is-spaced is-2 ">
              {t("payment-links_hero_title")}
            </h1>
            <h2
              style={{ maxWidth: 450 }}
              className="subtitle main-subtitle has-text-white "
            >
              {t("payment-links_hero_subtitle")}
            </h2>
            <a
              style={{ maxWidth: 350 }}
              className=" is-flex is-vcentered is-hspaced has-text-white is-alert-link has-alert-orange"
              href={guides[locale]}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t("payment-links_hero_alert")}
              <span className="icon is-small has-margin-left-3">
                <ArrowLeftIcon />
              </span>
            </a>
          </div>
          <div className="column">
            <Img fluid={imageFluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

const Section2 = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const items = [
    {
      icon: PaymentLinkIcon,
      text: t("payment-links_section2_item1_text"),
    },
    {
      icon: PaymentSendIcon,
      text: t("payment-links_section2_item2_text"),
    },
    {
      icon: PaymentClickIcon,
      text: t("payment-links_section2_item3_text"),
    },
    {
      icon: PaymentAccountIcon,
      text: t("payment-links_section2_item4_text"),
    },
  ]
  return (
    <section className="section section2">
      <div className="container">
        <SectionTitle
          title={t("payment-links_section2_title")}
          subtitle={t("payment-links_section2_subtitle")}
          titleColor=""
        />
        <div className="columns">
          {items.map((item, i) => (
            <div key={item.text} className="column">
              <div className="has-text-centered has-text-left-mobile is-flex-mobile is-vcentered">
                <span className="icon">
                  <img src={item.icon} alt="Icon" />
                </span>
                {isMobile ? (
                  <></>
                ) : (
                  <div className="path-wrapper is-hidden-mobile">
                    {i === 0 ? <></> : <div className="connect-line left" />}
                    <div className="circle has-margin-top-4 has-margin-bottom-4" />
                    {i === items.length - 1 ? (
                      <></>
                    ) : (
                      <div className="connect-line right" />
                    )}
                  </div>
                )}
                <p className="is-size-6 is-size-6-mobile item-text">
                  {item.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const Section3 = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const items = [
    {
      title: t("payment-links_section3_item1_title"),
      subtitle: t("payment-links_section3_item1_subtitle"),
      image: Section3Item1Image,
      text: t("payment-links_section3_item1_text"),
    },
    {
      title: t("payment-links_section3_item2_title"),
      subtitle: t("payment-links_section3_item2_subtitle"),
      image: Section3Item2Image,
      text: t("payment-links_section3_item2_text"),
    },
  ]
  return (
    <section className="section section3 has-margin-bottom-7">
      <div className="container">
        {!isMobile && (
          <div className="dots" style={{ backgroundImage: `url(${Dot}` }} />
        )}
        <SectionTitle
          title={t("payment-links_section3_title")}
          subtitle={t("payment-links_section3_subtitle")}
          titleColor="white"
          subtitleColor="secondary"
        />
        <div className="columns is-centered">
          <div className="column is-10">
            <div className="columns is-variable is-6">
              {items.map((item, i) => (
                <div key={item.title} className="column">
                  <div className={`is-card card-${i + 1}`}>
                    <h4 className="title has-text-centered">{item.title}</h4>
                    <p className="subtitle has-text-centered has-margin-bottom-5 is-6">
                      {item.subtitle}
                    </p>
                    <div className="has-text-centered has-margin-bottom-5">
                      <img src={item.image} alt={item.title} />
                    </div>
                    <p className="is-size-6">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section4 = () => {
  const { t } = useTranslation()
  const intl = useIntl()
  const isWithComma = intl.locale === "ro" || intl.locale === "it"
  const { countryCode, isLoading } = useCountryCode()
  return (
    <section className="section section4 has-margin-bottom-7">
      <div className="container">
        <SectionTitle
          title={t("payment-links_section4_title")}
          subtitle={t("payment-links_section4_subtitle")}
          className="has-margin-bottom-7"
        />
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="columns item is-vcentered is-variable is-8 has-margin-bottom-7">
              <div className="column text-wrapper is-three-fifths">
                <h5 className="title is-4 is-size-5-mobile">
                  {t("payment-links_section4_item1_title")}
                </h5>
                <p
                  style={{ maxWidth: 450 }}
                  className="is-size-5 is-size-6-mobile"
                >
                  {t("payment-links_section4_item1_text")}
                </p>
              </div>
              <div className="column image-wrapper">
                <img
                  src={Section4Item1Image}
                  alt={t("payment-links_section4_item1_title")}
                />
              </div>
            </div>
            <div className="columns item-2 item is-vcentered is-variable is-8 has-margin-bottom-7">
              <div className="column image-wrapper ">
                <img
                  src={Section4Item2Image}
                  alt={t("payment-links_section4_item2_text")}
                />
              </div>
              <div className="column text-wrapper ">
                <h5 className="title is-4 is-size-5-mobile">
                  {t("payment-links_section4_item2_title")}
                </h5>
                <p className="is-size-5 is-size-6-mobile">
                  {t("payment-links_section4_item2_text")}
                </p>
              </div>
            </div>
            <div className="columns has-margin-bottom-7 item is-vcentered">
              <div className="column ">
                <h5 className="title is-4 is-size-5-mobile">
                  {t("payment-links_section4_item3_title")}
                </h5>
                <p
                  style={{ maxWidth: 350 }}
                  className="is-size-5 is-size-6-mobile"
                >
                  {t("payment-links_section4_item3_text")}
                </p>
              </div>
              <div className="column image-wrapper has-text-right-desktop">
                <img
                  src={Section4Item3Image}
                  alt={t("payment-links_section4_item3_title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-10">
            <div className="columns alert-wrapper is-vcentered">
              <div className="column is-2">
                <p className="has-text-white">
                  {t("payment-links_section4_alert_left")}
                </p>
                <span className="percentage is-size-1">
                  {isLoading
                    ? "0.0"
                    : countryCode === "ro"
                    ? isWithComma
                      ? "0,99"
                      : "0.99"
                    : isWithComma
                    ? "1,3"
                    : "1.3"}
                  %
                </span>
              </div>
              <div className="column ">
                <p className="is-size-4 has-text-white is-size-6-mobile">
                  {t("payment-links_section4_alert_center_text_1")}
                </p>
                <p className="is-size-4 has-text-white is-size-6-mobile">
                  {t("payment-links_section4_alert_center_text_2")}
                </p>
              </div>
              <div className="column is-one-fifth">
                <Link
                  to="/pricing"
                  className="button is-rounded is-primary is-fullwidth is-medium has-text-weight-bold"
                >
                  {t("Pricing")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section5 = () => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  const items = [
    {
      title: t("payment-links_section5_item1_title"),
      shortAnswer: t("payment-links_section5_item1_shortAnswer"),
      answer: t("payment-links_section5_item1_answer"),
    },
    {
      title: t("payment-links_section5_item2_title"),
      shortAnswer: t("payment-links_section5_item2_shortAnswer"),
      answer: t("payment-links_section5_item2_answer"),
    },
    {
      title: t("payment-links_section5_item3_title"),
      shortAnswer: t("payment-links_section5_item3_shortAnswer"),
      answer: t("payment-links_section5_item3_answer"),
    },
    {
      title: t("payment-links_section5_item4_title"),
      shortAnswer: t("payment-links_section5_item4_shortAnswer"),
      answer: t("payment-links_section5_item4_answer"),
    },
    {
      title: t("payment-links_section5_item5_title"),
      shortAnswer: t("payment-links_section5_item5_shortAnswer"),
      answer: t("payment-links_section5_item5_answer"),
    },
    {
      title: t("payment-links_section5_item6_title"),
      shortAnswer: t("payment-links_section5_item6_shortAnswer"),
      answer: t("payment-links_section5_item6_answer"),
    },
  ]
  const [selectedItem, setSelectedItem] = useState(items[0])
  return (
    <section className="section section5 has-margin-bottom-6">
      <div className="container">
        <h4 className="title is-2 has-text-centered has-margin-bottom-7">
          {t("payment-links_section5_title")}
        </h4>
        <div className="columns is-centered">
          <div className="column is-10">
            <div className="columns is-variable is-8 is-vcentered ">
              <div className="column is-7">
                <FAQ
                  items={items}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  hasInlineAnswer={isMobile}
                />
              </div>
              {!isMobile && (
                <div className="column">
                  <AnimatePresence exitBeforeEnter>
                    <motion.div
                      key={selectedItem.answer}
                      initial={{ y: -10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 10, opacity: 0 }}
                    >
                      <p className="is-size-5 short-answer has-margin-bottom-4">
                        {selectedItem.shortAnswer}
                      </p>
                      <p className="is-size-5 answer">{selectedItem.answer}</p>
                    </motion.div>
                  </AnimatePresence>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Section6 = () => {
  const { t } = useTranslation()
  return (
    <section className="section section6 has-text-centered">
      <div className="container">
        <h4 className="title is-spaced has-margin-bottom-6">
          {t("payment-links_section6_title")}
        </h4>
        <Link
          to="/contact"
          className="button is-rounded is-medium is-fullwidth"
        >
          <span>{t("payment-links_section6_button")}</span>
          <span className="icon">
            <ArrowIcon />
          </span>
        </Link>
      </div>
    </section>
  )
}
const PaymentLinkPage = ({ data: files }) => (
  <Layout stripeClass="is-blue-violet" pageName="payment-links">
    <SEO title="Payment Links | Twispay" />
    <HeroSection
      imageFluid={files.HeroImage.childImageSharp.fluid}
      guides={{
        en: files.GuideEN.publicURL,
        ro: files.GuideRO.publicURL,
        de: files.GuideDE.publicURL,
        it: files.GuideIT.publicURL,
      }}
    />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
  </Layout>
)

export const files = graphql`
  query {
    HeroImage: file(relativePath: { eq: "payment-links-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    GuideEN: file(relativePath: { eq: "payment links - EN.pdf" }) {
      publicURL
    }
    GuideRO: file(relativePath: { eq: "payment links - RO.pdf" }) {
      publicURL
    }
    GuideDE: file(relativePath: { eq: "payment links - DE.pdf" }) {
      publicURL
    }
    GuideIT: file(relativePath: { eq: "payment links - IT.pdf" }) {
      publicURL
    }
  }
`

export default PaymentLinkPage
